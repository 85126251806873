
import React from 'react';
import { connect } from 'react-redux';
import { Container, Label, Table, UncontrolledPopover, PopoverBody, Button } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import moment from 'moment';

import FormBase from 'jsx/components/core/form/components/FormBase';
import FormInputSelect from 'jsx/components/core/form/components/FormInputSelect';
import DirectDownload from 'jsx/components/core/form/components/DirectDownload';

import { fetchAnalysisGroupMembers } from 'jsx/components/manage/actions/analysis_group_members';

import { BenchmarkDataRow } from '../../components/benchmarking/BenchmarkDataRow';
import { BenchmarkSection } from '../../components/benchmarking/BenchmarkSection';
import { BenchmarkChart } from '../../components/benchmarking/BenchmarkChart';

import { fetchProbeTemplateByTag, getProbePdf, downloadProbePdf } from '../../actions/admin/probe';
import { fetchWarehouseProperty, fetchWarehouseGroup, fetchWarehouseProbeProperty, fetchWarehouseProbeGroup, fetchWarehouseGroupAssocs } from '../../actions/warehouse';

import { controls } from '../../forms/benchmarking/benchmarks';

const addHeight = (text) => (
  <div className="d-flex justify-content-center">
    <span className="mb-1">{text}</span>
  </div>
);

const getPopover = (message, target) => (
  <UncontrolledPopover placement="right" target={target} trigger="hover">
    <PopoverBody>
      <p className="p-0 m-0">{message}</p>
    </PopoverBody>
  </UncontrolledPopover>
);

class Benchmark extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      benchmarkControls: controls,
      period_id: null,
      property_id: null,
      isChartOpen: false,
      sortChartBy: 'to_date',
      chartData: [],
      chartTitle: '',
      chartExtra: {},
      probeTag: 'standard_probe'
    };

    this.runReport = this.runReport.bind(this);
    this.onHandleChange = this.onHandleChange.bind(this);
    this.getSubProbes = this.getSubProbes.bind(this);
    this.get3yrSubProbes = this.get3yrSubProbes.bind(this);
    this.setChart = this.setChart.bind(this);
    this.setChartModal = this.setChartModal.bind(this);
    this.getColour = this.getColour.bind(this);
    this.toggleChartSort = this.toggleChartSort.bind(this);
    this.sortChart = this.sortChart.bind(this);
    this.openPdf = this.openPdf.bind(this);
    this.downloadPdf = this.downloadPdf.bind(this);
  }

  async componentDidMount() {
    const { benchmarkControls, probeTag } = this.state;
    const { userProbes } = this.props.warehouse;

    // Fetch Groups enabled for property
    const groups = await this.props.dispatch(fetchAnalysisGroupMembers({property_id: this.props.property_id}));
    benchmarkControls.group_id.options = groups.rows.map(row => ({id: row?.group?.id, name: row?.group?.name}));

    if (groups?.rows?.length > 0) {
      benchmarkControls.group_id.value = groups.rows[0].group.id;
    }

    // Only load combo in portal
    if (this.props.showProbeSelect) {
      benchmarkControls.probe_id.options = userProbes.map(probe => ({id: probe?.id, name: `${probe.property.name} - ${probe?.period_rolling_description}`}));
      benchmarkControls.probe_id.value = this.props.probe_id;
    }

    // Fetch probe template
    this.props.dispatch(fetchProbeTemplateByTag(probeTag, this.props.property_id));

    this.setState({period_id: this.props.period_id, property_id: this.props.property_id}, () => {
      this.runReport();
    });
  }

  toggleChartSort() {
    let { sortChartBy } = this.state;
    let { chartData } = this.state;

    sortChartBy = (sortChartBy === 'property' ? 'to_date' : 'property');

    chartData = this.sortChart(sortChartBy, chartData);
    this.setState({chartData, sortChartBy});
  }

  // eslint-disable-next-line class-methods-use-this
  sortChart(sortChartBy, chartData) {
    if (sortChartBy === 'to_date') {
      chartData.sort((left, right) => new Date(left[sortChartBy]) - new Date(right[sortChartBy]));
    } else {
      const toEnd = 1;
      chartData.sort((a, b) => (a[sortChartBy] ? a[sortChartBy].localeCompare(b[sortChartBy]) : toEnd));
    }

    return chartData;
  }

  setChartModal(isChartOpen) {
    this.setState({isChartOpen});
  }

  // eslint-disable-next-line class-methods-use-this
  getColour(to_date, periods) {
    const year = moment(to_date).format('YYYY');
    return periods.find(period => period.year === year).colour;
  }

  async get3yrSubProbes(row, probePropertyRow, probeGroupRow, column_tag) {
    const { sortChartBy } = this.state;

    // Get top20 metric ids for 3 years
    const metric_ids = probeGroupRow?.benchmark_3yr_average?.data?.metric_ids;

    // Build periods
    const periods = [];
    periods.push(
      {
        year: moment(probeGroupRow.to_date).format('YYYY'),
        colour: '#1f77b4',
        probe_period: moment(probeGroupRow.to_date).format('MMM-YY')
      }
    );
    periods.push(
      {
        year: moment(probeGroupRow.to_date).subtract(1, 'year').format('YYYY'),
        colour: '#ff7f0e',
        probe_period: moment(probeGroupRow.to_date).subtract(1, 'year').format('MMM-YY')
      }
    );
    periods.push(
      {
        year: moment(probeGroupRow.to_date).subtract(2, 'years').format('YYYY'),
        colour: '#2ca02c',
        probe_period: moment(probeGroupRow.to_date).subtract(2, 'year').format('MMM-YY')
      }
    );

    // Accumulate probes from the 3 periods
    let subProbes = [];
    await Promise.all(
      await metric_ids.map(async metric_id => {
        const probes = await this.props.dispatch(fetchWarehouseGroupAssocs(metric_id, 'benchmark_top20'));
        subProbes = subProbes.concat(probes);
      })
    );

    // Build chart data
    let chartData = subProbes.map(subProbe => ({
        name: `${subProbe?.metric?.period.probe_period} - ${subProbe?.metric?.property?.name}`,
        property: subProbe?.metric?.property?.name,
        value: subProbe?.metric?.client_benchmark_values?.data?.value,
        to_date: subProbe?.metric?.period.date,
        colour: this.getColour(subProbe?.metric?.period.date, periods)
      }));

    // Sort chart by date
    chartData = this.sortChart(sortChartBy, chartData);

    this.setChart(chartData, row, probePropertyRow, column_tag, periods);
  }

  async getSubProbes(row, probePropertyRow, probeGroupRow, column_tag) {
    const subProbes = await this.props.dispatch(fetchWarehouseGroupAssocs(probeGroupRow?.id, column_tag));

    const chartData = subProbes.map(subProbe => ({
        name: subProbe?.metric?.property?.name,
        value: subProbe?.metric?.client_benchmark_values?.data?.value,
        colour: '#1f77b4'
      }));

    this.setChart(chartData, row, probePropertyRow, column_tag);
  }

  async setChart(chartData, row, probePropertyRow, column_tag, periods = []) {
    const chartExtra = {};
    chartExtra.value = `${probePropertyRow?.client_benchmark_values?.data?.value.toFixed(2)}${row?.metric?.unit_attributes?.suffix}`;
    chartExtra.rawValue = probePropertyRow?.client_benchmark_values?.data?.value;
    // chartExtra.value = `${probePropertyRow?.client_entered?.data?.value.toFixed(2)}${row?.metric?.unit_attributes?.suffix}`;
    chartExtra.unit_attributes = row?.metric?.unit_attributes;
    chartExtra.metric_name = row?.metric?.name;
    chartExtra.column_tag = column_tag;
    chartExtra.period_description = this.props.period_description;
    chartExtra.property_name = this.props.property_name;
    chartExtra.showChartSort = (column_tag === 'benchmark_3yr_average');
    chartExtra.periods = periods;

    const chartTitle = `${chartExtra.metric_name} (${chartExtra.value}) - ${column_tag}`;
    this.setState({chartData, chartTitle, chartExtra}, () => {
      this.setChartModal(true);
    });
  }

  async runReport() {
    const { benchmarkControls, period_id, property_id } = this.state;

    // Check and set group
    const selectedGroupId = benchmarkControls.group_id.value;

    // Get warehouse property values
    // await this.props.dispatch(fetchWarehouseProperty(period_id, property_id));
    await this.props.dispatch(fetchWarehouseProbeProperty(period_id, property_id));

    // Get analysis group values
    // await this.props.dispatch(fetchWarehouseGroup(period_id, selectedGroupId));
    await this.props.dispatch(fetchWarehouseProbeGroup(period_id, selectedGroupId));
    this.setState({validationMessage: null});
  }

  onHandleChange(fieldName, value) {
    const { benchmarkControls } = this.state;
    const { userProbes } = this.props.warehouse;

    benchmarkControls[fieldName].value = value;

    const probe = userProbes.find(userProbe => userProbe.id === benchmarkControls.probe_id.value);
    this.setState({benchmarkControls, period_id: probe?.period_id, property_id: probe?.property_id}, () => this.runReport());
  }

  renderListviewRows (section, isGroupPublished) {
    // const { probeRows, probeGroups } = this.props.warehouse;
    const { probePropertyRows, probeGroupRows } = this.props.warehouse;

    const lsvRows = [];
    let prevCaption;

    section.groups.map(group => {
      group.rows.map(row => {
        const lsvRow = {
          group_caption: (prevCaption !== group.caption ? group.caption : '')
        };
        lsvRow.id = row.id;
        lsvRow.metric_name = row.metric.name;
        lsvRow.metric_key = row.metric.key;
        lsvRow.formula = row.metric.formula;
        lsvRow.description = row.metric.description;
        lsvRow.unit = row.metric.unit_attributes.suffix;
        lsvRow.attributes = row.attributes;
        lsvRow.show_benchmarks = row.show_benchmarks;

        const results = [];
        const probePropertyRow = probePropertyRows.find(probeRow => probeRow.metric_id === row.metric.id && probeRow.kpigroup_id === group.id);
        const gaugeFn = () => {};

        const prefix = probePropertyRow?.client_entered?.unit_attributes.prefix || '';
        const suffix = probePropertyRow?.client_entered?.unit_attributes.suffix || '';

        const options = {maximumFractionDigits: 2};
        if (probePropertyRow) {
          results.push({iconFn: gaugeFn, value: `${prefix}${probePropertyRow?.client_entered?.data?.value?.toLocaleString(undefined, options) || 0}${suffix}`});
          results.push({iconFn: gaugeFn, value: `${prefix}${probePropertyRow?.client_benchmark_values?.data?.value?.toLocaleString(undefined, options) || 0}${suffix}`});
          results.push({value: false});
        }

        const probeGroupRow = probeGroupRows.find(probeGroup => probeGroup.metric_id === row.metric.id && probeGroup.kpigroup_id === group.id);
        let benchmark_top20 = ( probeGroupRow?.benchmark_top20?.data ? `${prefix}${probeGroupRow?.benchmark_top20?.data?.toLocaleString(undefined, options)}${suffix}` : false );
        let benchmark_1yr_average = (probeGroupRow?.benchmark_1yr_average?.data ? `${prefix}${probeGroupRow?.benchmark_1yr_average?.data?.toLocaleString(undefined, options)}${suffix}` : false);
        const benchmark_3yr_average = (probeGroupRow?.benchmark_3yr_average?.data?.value ? `${prefix}${probeGroupRow?.benchmark_3yr_average?.data?.value.toLocaleString(undefined, options)}${suffix}` : false);

        if (!isGroupPublished && !this.props.showSubProbesIcon) {
          benchmark_top20= false;
          benchmark_1yr_average= false;
        }

        results.push({
          iconFn: () => this.getSubProbes(row, probePropertyRow, probeGroupRow, 'benchmark_1yr_average'), 
          value: benchmark_1yr_average 
        });
        results.push({
          iconFn: () => this.getSubProbes(row, probePropertyRow, probeGroupRow, 'benchmark_top20'), 
          value: benchmark_top20
        });
        results.push({
          iconFn: () => this.get3yrSubProbes(row, probePropertyRow, probeGroupRow, 'benchmark_3yr_average'), 
          value: benchmark_3yr_average
        });

        lsvRows.push(
          <BenchmarkDataRow
            key={lsvRow.id}
            categoryName={lsvRow.group_caption}
            metricName={lsvRow.metric_name}
            results={results}
            showSubProbesIcon={this.props.showSubProbesIcon}
          />
        );
        prevCaption = group.caption;
      });
    });

    return lsvRows;
  };

  renderSections(isGroupPublished) {
    const { probeTemplate } = this.props.probe;

    if (probeTemplate.sections) {
      const sections = probeTemplate.sections.map((section, index) => (
        <React.Fragment key={index}>
          <BenchmarkSection  name={section.caption} colour="green-20" />
          {this.renderListviewRows(section, isGroupPublished)}
        </React.Fragment>
      ));
  
      return sections;
    }

    return (<></>);
  }

  async openPdf () {
    const { benchmarkControls, probeTag, period_id, property_id } = this.state;
    const group_id = benchmarkControls.group_id.value;

    // Get warehouse property values
    const response = await this.props.dispatch(getProbePdf(probeTag, {period_id, property_id, group_id}));

    if (!response?.data) return;

    // Handle the response containing the PDF file
    const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
    const pdfUrl = window.URL.createObjectURL(pdfBlob);

    // Open the PDF in a new tab
    window.open(pdfUrl, '_blank');
  }

  async downloadPdf(onProgressChange) {
    const { benchmarkControls, probeTag, period_id, property_id } = this.state;
    const group_id = benchmarkControls.group_id.value;

    // Get warehouse property values and return pdf
    return this.props.dispatch(downloadProbePdf(probeTag, {period_id, property_id, group_id}, onProgressChange));
  }

  render() {
    const { benchmarkControls, validationMessage, isChartOpen, chartData, chartTitle, chartExtra, sortChartBy} = this.state;

    const { probePropertyRows, probeGroupRows } = this.props.warehouse;

    const probeRow = probePropertyRows?.find(row => row.client_entered.key === 'return_on_assets');
    const probeUpdated = (probeRow ? moment(probeRow.updated).format('YYYY-MM-DD HH:mm') : '-');

    let groupMessage = 'This Benchmark Group have not been calculated yet';
    let isGroupPublished = false;
    let benchMarkUpdated = '-';

    if (probeGroupRows?.length > 0) {
      const probeGroup = probeGroupRows?.find(row => row.metric.key === 'return_on_assets');

      if (probeGroup) {
        groupMessage = (probeGroup.is_published ? '' : 'This Benchmark Group has not yet been published');
        isGroupPublished = probeGroup.is_published;
        benchMarkUpdated = moment(probeGroup.updated).format('YYYY-MM-DD HH:mm');
      }
    }

    // Generate download filename
    const filename = `${probeRow?.property?.name}_${probeRow?.period?.probe_period}.pdf`.replaceAll(' ', '').toLowerCase();

    return (
      <Container fluid className="h-100 p-0">
        <div className="d-flex justify-content-between bg-light border border-gray-20 rounded p-0 m-1">
          {/* <Button color="primary" size="sm" className="m-2" onClick={this.openPdf}>Export Report to PDF</Button> */}
          <DirectDownload
            classNames="m-2"
            handleDownload={(onProgressChange) => this.downloadPdf(onProgressChange)}
            caption="Download PDF"
            size="sm"
            showIcon
            showLabel={false}
            width={185}
            buttonType="primary"
            filename={filename}
          />
          <div className="d-flex justify-content-start">
            { this.props.showProbeSelect && (
              <>
                <Label className="ml-3" style={{marginTop: 10}}>Probe</Label>
                <FormInputSelect className="m-1" hideCaption handleChange={(event) => this.onHandleChange('probe_id', event.value)} control={benchmarkControls.probe_id} />
              </>
            )}
          
            <Label className="ml-3" style={{marginTop: 10}}>Benchmark Group</Label>
            <FormInputSelect className="m-1" hideCaption handleChange={(event) => this.onHandleChange('group_id', event.value)} control={benchmarkControls.group_id} />
            <small className="ml-3 text-danger" style={{marginTop: 14}}>{groupMessage}</small>
          </div>
          
        </div>
        {validationMessage && (
          <h4 className="text-center m-3 mt-4 text-danger">{validationMessage}</h4>
        )}

        {!validationMessage && probePropertyRows.length > 0 && (
          <>
            <BenchmarkChart
              setModal={this.setChartModal}
              isOpen={isChartOpen}
              chartData={chartData}
              chartTitle={chartTitle}
              chartExtra={chartExtra}
              toggleChartSort={this.toggleChartSort}
              sortChartBy={sortChartBy}
            />
            <Table hover bordered responsive>
              <thead className="text-center border-top">
                <tr className="">
                  <th colSpan="2" className="border-bottom-0" style={{ fontSize: '14px' }}></th>
                  <th colSpan="3" className="border-bottom-0" style={{ fontSize: '14px' }}>
                    Your Result
                  </th>
                  <th colSpan="3" className="border-bottom-0" style={{ fontSize: '14px' }}>
                    Benchmark Group Results
                  </th>
                </tr>
                <tr className="border-top">
                  <th></th>
                  <th>{addHeight('Metric')}</th>
                  <th className="bg-yellow-20">
                    Using<br/>Your $/kg
                    <Icon
                      id="ClientValuesInfo"
                      as="button"
                      name="info-circle"
                      title="Info"
                      className="ml-1 p-0"
                    />
                    {getPopover('Based on the values you entered in the stockflows and cropflows', 'ClientValuesInfo')}
                  </th>
                  <th className="bg-pink-20">
                    Using<br/>Standardised $/kg
                    <Icon
                      id="BenchmarkValuesInfo"
                      as="button"
                      name="info-circle"
                      title="Info"
                      className="ml-1 p-0"
                    />
                    {getPopover('Based on RCS Standardised values ($/kg)', 'BenchmarkValuesInfo')}
                  </th>
                  <th className="bg-gray-20 text-secondary text-nowrap">
                    3 Year Average
                    <Icon
                      id="ThreeYrBenchmarkValuesInfo"
                      as="button"
                      name="info-circle"
                      title="Info"
                      className="ml-1 p-0"
                    />
                    {getPopover('3 Year average based on RCS Standardised values ($/kg)', 'ThreeYrBenchmarkValuesInfo')}
                  </th>
                  <th className="bg-blue-20">{addHeight('Group Average')}</th>
                  <th className="bg-blue-20">{addHeight('Top 20%')}</th>
                  <th className="bg-blue-20">Top 20%<br/>3 Year Average</th>
                </tr>
              </thead>
              <tbody>
                {this.renderSections(isGroupPublished)}
              </tbody>
            </Table>

            <small className="ml-2" >Probe Updated: <span className='font-bolder'>{probeUpdated}</span> - Benchmark Group Updated: <span className='font-bolder'>{benchMarkUpdated}</span></small>

            <div className="m-2">
              <small>
                <span className='font-bolder'>Your Values</span> - Price per kilogram specific to your operation, reflecting market conditions and product quality unique to your business. These values have been determined by you in your stockflow.
              </small>
            </div>
            <div className="m-2">
              <small>
                <span className='font-bolder'>RCS Standardised Values</span> - Derived from published industry prices to reflect market value for each class of animal and ensure consistency across businesses for meaningful performance comparisons in benchmarking.
              </small>
            </div>

            
          </>
        )}
      </Container>
    );
  };
}

const mapStoreToProps = ({ realm, probe, warehouse, enterprises, analysis_groups }) => ({
  realm,
  probe,
  warehouse,
  enterprises,
  analysis_groups
});

export default connect(mapStoreToProps)(Benchmark);
