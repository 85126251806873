import { downloadBlob, get, post, put, remove, download } from 'jsx/lib/genericAction';

import {
    apiProbe,
} from 'jsx/constants/api';

export function fetchProbeTemplate(probe_id) {
  return async (dispatch) => await get(dispatch, `FETCH_PROBE_TEMPLATE`, `${apiProbe}/published/${probe_id}`);
}

export function updateProbeTemplate(data) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_PROBE_TEMPLATE_VERSION', `${apiProbe}/version/${data.id}`, data);
}

export function fetchProbeTemplateByTag(tag, property_id) {
  return async (dispatch) => await get(dispatch, `FETCH_PROBE_TEMPLATE`, `${apiProbe}/published/bytag/${tag}`, { property_id });
}

export function fetchProbeGroupRow(id) {
  return async (dispatch) => await get(dispatch, `FETCH_PROBE_ROW`, `${apiProbe}/rows/${id}`);
}

export function createProbeGroupRow(data) {
  return async (dispatch) => post(dispatch, 'CREATE_PROBE_ROW', `${apiProbe}/rows`, data);
}

export function updateProbeGroupRow(data) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_PROBE_ROW', `${apiProbe}/rows/${data.id}`, data);
}

export function removeProbeGroupRow(id) {
  return async (dispatch) => remove(dispatch, 'REMOVE_PROBE_ROW', `${apiProbe}/rows/${id}`);
}

export function createProbeSection(data) {
  return async (dispatch) => post(dispatch, 'CREATE_PROBE_SECTION', `${apiProbe}/sections`, data);
}

export function removeProbeSection(id) {
  return async (dispatch) => remove(dispatch, 'REMOVE_PROBE_SECTION', `${apiProbe}/sections/${id}`);
}

export function createProbeSectionGroup(data) {
  return async (dispatch) => post(dispatch, 'CREATE_PROBE_SECTION_GROUP', `${apiProbe}/sections/groups`, data);
}

export function updateProbeSectionGroup(data) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_PROBE_SECTION_GROUP', `${apiProbe}/sections/groups/${data.id}`, data);
}

export function getProbePdf(tag, params) {
  return async (dispatch) =>
    downloadBlob(dispatch, 'EXPORT_PROBE', `${apiProbe}/export/bytag/${tag}`, params);
}

export function downloadProbePdf(tag, params, onProgressChange) {
  return async dispatch => download(
    dispatch,
    'DOWNLOAD_PROBE_PDF',
    `${apiProbe}/export/bytag/${tag}`,
    onProgressChange,
    params
  );
}
