
import { defaults, allowed } from '../../../../lib/genericReducer';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    responseMessage: null,
    probeRows: [],
    probeGroups: [],
    probePropertyRows: [],
    probeGroupRows: [],
    probes: [],
    groups: [],
    groupsMinMax: [],
    userProbes: [],
    metrics: [],
    eligibles: [],
    distinct_properties: [],
    filters: {
      property_id: [],
      period_id: [],
      metric_id: [],
      group_id: []
    }
  },
  action,
) {
  const types = ['SCHEDULE', 'WAREHOUSE', 'BATCH', 'LOG'];
  if (!allowed(types, action)) return state;

  const updatedState = {
    ...state,
    ...defaults(action),
  };
  
  switch (action.type) {
    case 'FETCH_WAREHOUSE_DISTINCT_PROPERTIES_FULFILLED': {
      return {
        ...state,
        distinct_properties: action.payload,
        fetching: false
      };
    }
    case 'FETCH_WAREHOUSE_ELIGIBLE_PROPERTIES_FULFILLED': {
      return {
        ...state,
        eligibles: action.payload,
        fetching: false
      };
    }
  
    case 'FETCH_WAREHOUSE_METRICS_FULFILLED': {
      return {
        ...state,
        metrics: action.payload,
      };
    }

    case 'FETCH_WAREHOUSE_USER_PROBES_FULFILLED': {
      return {
        ...state,
        userProbes: action.payload,
      };
    }
    case 'FETCH_WAREHOUSE_PROBES_FULFILLED': {
      return {
        ...state,
        probes: action.payload,
      };
    }
    case 'FETCH_WAREHOUSE_PROPERTY_FULFILLED': {
      return {
        ...state,
        probeRows: action.payload,
      };
    }
    case 'FETCH_WAREHOUSE_PROBE_PROPERTY_FULFILLED': {
      return {
        ...state,
        probePropertyRows: action.payload,
      };
    }
    case 'FETCH_WAREHOUSE_GROUPS_MINMAX_FULFILLED': {
      return {
        ...state,
        groupsMinMax: action.payload,
      };
    }
    case 'FETCH_WAREHOUSE_GROUPS_FULFILLED': {
      return {
        ...state,
        groups: action.payload,
      };
    }
    case 'FETCH_WAREHOUSE_GROUP_FULFILLED': {
      return {
        ...state,
        probeGroups: action.payload,
      };
    }
    case 'FETCH_WAREHOUSE_PROBE_GROUP_FULFILLED': {
      return {
        ...state,
        probeGroupRows: action.payload,
      };
    }
    case 'SET_WAREHOUSE_FILTER_FULFILLED': {
      return {
        ...state,
        filters: action.payload,
      };
    }
    default:
      break;
  }

  return updatedState;
}
